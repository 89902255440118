html {
  font-size: 10px;
}

html,
body {
  padding: 0;
  margin: 0;
}

html,
body,
#root,
.App,
.fullHeight {
  height: 100%;
}

*, ::before, ::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1;
  color: #363636;
}

h1 {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 auto 2rem;
  font-size: 3.0rem;
  line-height: 3.2rem;
  color: #363636;
  text-align: left;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: #d3d3d3;
}

h2 {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 auto 2rem;
  font-size: 2.5rem;
  line-height: 2.7rem;
  color: #363636;
  text-align: left;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: #d3d3d3;
}

h3 {
  display: block;
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto 2rem;
  font-size: 1.9rem;
  line-height: 2.7rem;
  color: #363636;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: #d3d3d3;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a, a:hover {
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.main {
  width: 100%;
  height: calc(100% - 6.5rem);
  max-width: 1450px;
  padding: 0 10px;
  margin: 0 auto;
}

.controlPanel {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  padding: 3rem 0 6rem 25.0rem;
  border-bottom: 1px solid #d3d3d3;

  .sidebar {
    position: absolute;
    top: 0; left: 0;
    width: 25.0rem;
    height: 100%;
    z-index: 1;
    border-right: 1px solid #d3d3d3;
  }

  .content {
    padding: 0 10px;
  }
}

.adminLayout {
  height: calc(100% - 6.5rem - 5.8rem - 3.6rem);
}

.dashboardLayout {
  height: calc(100% - 6.5rem - 5.8rem);
}

.btn-solid-lg {
	display: inline-block;
	padding: 3.25rem 5.25rem 3.25rem 5.25rem;
	border: 1px solid #00c1ff;
	border-radius: 64px;
	background-color: #00c1ff;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.1s;
}

.btn-solid-lg:hover {
	border: 1px solid #00c1ff;
	background-color: rgba(0, 0, 0, 0.5);
	color: #00c1ff; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

@media only screen and (max-width: 980px) {
  html {
    font-size: 9px;
  }

  .controlPanel {
    padding: 0 10px;

    .sidebar {
      position: relative;
      width: 100%;
      border: none;
      margin-bottom: 2.5rem;
    }
  }
}

.loadingBox {
	z-index: 1000;
	position: fixed;
	display: table;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.75);
}
.boxInner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
.boxBg {
	width: 40px;
	height: 40px;
}
.boxIcon {
	width: 100%;
	height: 100%;
}